import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorPageImage from "../../assets/img/errorpage.svg?react";
import { CDataTypography } from "../../components/CDataTypography";
import { Box } from "@mui/material";
import { Button } from "react-bootstrap";

interface IGenericErrorPage {
  error: Error;
  /** Indicates whether the error renders inside the dashboard component or outside of it */
  isOutsideDashboard: boolean;
  resetErrorBoundary: () => void;
}

export function GenericErrorPage(props: IGenericErrorPage) {
  const { isOutsideDashboard, resetErrorBoundary } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const originalPathName = useRef(pathname);

  // Automatically resets the error state if the user navigates to a different page
  useEffect(() => {
    if (pathname !== originalPathName?.current) {
      resetErrorBoundary();
    }
  }, [pathname, resetErrorBoundary]);

  return (
    <Box
      sx={{
        maxWidth: "500px",
        margin: "2.5rem auto",
        textAlign: "center",
      }}
    >
      <ErrorPageImage className="mb-4" />
      <CDataTypography className="mb-3" variant="typography-variant-headline-1">
        Internal Server Error
      </CDataTypography>
      <CDataTypography className="mb-3">
        The server encountered an unexpected issue that prevented it from
        fulfilling your request. Please attempt your request again. If the issue
        persists, reach out to our support team at{" "}
        <a href="mailto:support@cdata.com">support@cdata.com</a> for further
        assistance.
      </CDataTypography>
      {isOutsideDashboard ? (
        <Button
          type={"primary"}
          onClick={() => {
            navigate("/");
            resetErrorBoundary();
          }}
        >
          Return to Connect Cloud{" "}
          <i className="fa-regular fa-sm fa-arrow-right ms-1" />
        </Button>
      ) : null}
    </Box>
  );
}

export const logBoundaryErrorToDatadog = (
  error: Error,
  info: { componentStack?: string },
) => {
  const renderingError = new Error(error.message);
  renderingError.name = "ReactRenderingError";
  renderingError.stack = info.componentStack ?? "";
  renderingError.cause = error;
  window.DD_RUM?.addError(renderingError);
};
