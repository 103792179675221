import { useAPI } from "../components/useAPI";
import { RequestType } from "../components/withAPI";
import { useDispatch } from "react-redux";
import {
  addAccountInfo,
  addCacheConnection,
  addDriversList,
  addSubscription,
  addUsage,
} from "../redux/actions";
import { useFeatureFlags } from "./useFeatureFlags";
import { useNavigate } from "react-router-dom";

export function useSessionState() {
  const api = useAPI();
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  const navigate = useNavigate();

  const initializeSessionState = async () => {
    const { status, payload, error } = await api.callAPI(
      RequestType.Get,
      "/users/session",
      "Failed to get session info due to the following error:",
    );
    if (status === 200) {
      featureFlags.setTrait("company", payload.organization);
      dispatch(addSubscription(payload.subscriptionInfo));
      dispatch(addDriversList(payload.driverList));
      dispatch(addUsage(payload.billingUsage));
      dispatch(addAccountInfo(payload.accountInfo));
      dispatch(addCacheConnection(payload.cacheConnection));
    } else {
      const isOemUser = window.location.pathname.startsWith("/oem/user/");
      // We cannot send OEM users to login or sign-up pages, they get short lived access tokens.
      if (!isOemUser) {
        if (status === 401) {
          window.location.href = encodeURI(
            window.location.origin + "/account/login",
          );
        } else {
          if (error && error.message && error.message.includes("signing up")) {
            const redirectUri = "/auth/user-sign-up";
            window.location.href = encodeURI(
              window.location.origin + redirectUri,
            );
          } else {
            navigate("/auth/500");
          }
        }
      } else {
        navigate("/auth/500");
      }
    }
  };

  const modalAndAPIs = {
    initializeSessionState: initializeSessionState,
  };

  return modalAndAPIs;
}
