// @ts-strict-ignore
import React, { useEffect, useState } from "react";
import {
  faCheck,
  faCreditCard,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  IAggregatePeriodList,
  BillingStatus,
  IBillingUsage,
  ISubscriptionInfo,
  UserRole,
} from "../../../models";

import { addSubscription } from "../../../redux/actions/subscriptionActions";
import { addUsage } from "../../../redux/actions/usageActions";
import { connect, useSelector } from "react-redux";

import { Button, Card, CardBody, Col, FormGroup, Row } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "react-bootstrap";
import { IModalFunctions } from "../../../layouts/Dashboard";
import { RequestType, BackendType } from "../../../components/withAPI";
import { SettingTabs } from "../Settings";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";
import { useAPI } from "../../../components/useAPI";
import { BillingUsageBars } from "./BillingUsageBars";
import { Status } from "../../../utility/Status";
import { BillingProvider } from "../../../models/Billing/BillingProvider";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";

interface IStripeBillingInfoTabProps {
  addSubscription: Function;
  addUsage: Function;
}

const StripeBillingInfoTab = (
  props: IModalFunctions & IStripeBillingInfoTabProps,
) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<ISubscriptionInfo>();
  const [billingUsageInfo, setBillingUsageInfo] = useState<IBillingUsage>();
  const [aggregateInfo, setAggregateInfo] = useState<IAggregatePeriodList>();
  const [toggleCancelBillingModal, setToggleCancelBillingModal] =
    useState<boolean>(false);
  const api = useAPI();
  const user = useSelector((s) => s.user);
  const isOem = user.role === UserRole.OEMAdmin;

  function getComponentName() {
    return "pages-settings-billinginfotab-StripeBillingInfoTab";
  }

  useEffect(() => {
    async function getToken() {
      await getUsage();
      await getSubscriptionInfo();
      await getAggregateInfo();
    }
    getToken();
  }, []);

  async function getUsage() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/usage",
      "Failed to get account usage due to the following error:",
      BackendType.AccountService,
    );
    if (status === 200) {
      setBillingUsageInfo(payload);
      props.addUsage(payload);
    }
  }

  async function getSubscriptionInfo() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/subscription",
      "Failed to get subscription info due to the following error:",
      BackendType.AccountService,
    );
    if (status === 200) {
      setSubscriptionInfo(payload);
      props.addSubscription(payload);
    }
  }

  async function getAggregateInfo() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/aggregates",
      "Failed to get aggregate info due to the following error:",
    );
    if (status === 200) {
      setAggregateInfo(payload);
    }
  }

  async function redirectToStripeBillingPortal(event: any) {
    event.preventDefault();
    let billingUrl = "";
    const data = `${window.location.protocol}//${window.location.host}${window.location.pathname}?tab=${SettingTabs.Billing}`;
    const { status, payload, error } = await api.callAPI(
      RequestType.Post,
      "/account/billing/createPortalSession",
      "",
      data,
      BackendType.AccountService,
    );
    if (status === 200) {
      billingUrl = payload.billingPortalUrl;
    } else {
      props.showErrorModal(
        "Failed to get billing portal redirect URL due to the following error:",
        error,
      );
    }
    if (billingUrl) {
      window.location.href = billingUrl;
    }
  }

  const CancelBillingModal = () => {
    return (
      <CDataModalV2
        className="cancel-billing-modal"
        fade={false}
        displayed={toggleCancelBillingModal === true}
        close={() => {
          setToggleCancelBillingModal(!toggleCancelBillingModal);
        }}
        title="Cancel Plan"
        spacedFooter={false}
        primaryButton={
          <Button
            color="primary"
            onClick={() => {
              window.location.href = `mailto:${getSalesEmailAddress()}`;
              setToggleCancelBillingModal(!toggleCancelBillingModal);
            }}
          >
            Contact Sales
          </Button>
        }
        secondaryButton={
          <Button
            color="secondary"
            onClick={() => {
              setToggleCancelBillingModal(!toggleCancelBillingModal);
            }}
          >
            Cancel
          </Button>
        }
      >
        <div>
          Looking to cancel your Connect Cloud subscription plan? Contact our
          Sales team today to assist you in this request.
        </div>
      </CDataModalV2>
    );
  };

  return (
    <Container fluid className={`p-0 ${getComponentName()}`}>
      <div className="container-fluid content-row">
        <Row>
          <div className="col d-flex">
            <Card className="billing-card">
              <CardBody>
                {subscriptionInfo?.billingStatus !==
                  BillingStatus.NewAccount && (
                  <Row className="mb-3">
                    <Col>
                      <h4>Billing</h4>
                    </Col>
                    <Col className="text-right">
                      <Button
                        onClick={(event: any) =>
                          redirectToStripeBillingPortal(event)
                        }
                        hidden={
                          subscriptionInfo?.billingProvider !==
                            BillingProvider.Stripe || isOem
                        }
                        className="float-end minor-margin-right"
                        type="button"
                        color="primary"
                        outline
                        data-testid="button-manage-billing"
                      >
                        <FontAwesomeIcon
                          icon={faCreditCard}
                          size="sm"
                          className="align-middle me-2 no-pointer-event"
                        />
                        <span className="align-middle">Manage Billing</span>
                      </Button>
                      <Button
                        onClick={() => setToggleCancelBillingModal(true)}
                        type="button"
                        className="invalid-button btn-outline-danger float-end minor-margin-right btn-spacing"
                      >
                        <i className="fa-regular fa-xmark align-middle"></i>
                        <span className="align-middle ms-2">Cancel Plan</span>
                      </Button>
                    </Col>
                  </Row>
                )}
                <FormGroup
                  className={
                    subscriptionInfo?.billingStatus !== BillingStatus.Canceled
                      ? "mb-3"
                      : "mb-0"
                  }
                >
                  <h5 className="mb-3">Billing Plan</h5>
                  <div id="accountName">
                    {subscriptionInfo?.billingStatus !== BillingStatus.Canceled
                      ? subscriptionInfo?.plan?.name
                      : null}
                    <Status
                      className={
                        subscriptionInfo?.billingStatus !==
                        BillingStatus.Canceled
                          ? "ms-2"
                          : "mt-2"
                      }
                      status={subscriptionInfo?.billingStatus}
                    />
                    {subscriptionInfo?.billingStatus ===
                    BillingStatus.Canceled ? (
                      <div className="mt-3 mb-0">
                        You currently do not have a CData Connect Cloud
                        subscription. Reactivate your plan today to take
                        advantage of all its features.
                      </div>
                    ) : null}
                  </div>
                </FormGroup>
                <FormGroup
                  hidden={
                    subscriptionInfo?.billingStatus === BillingStatus.Canceled
                  }
                  className="mb-3"
                >
                  <h5 className="mb-3">
                    {subscriptionInfo?.billingStatus === BillingStatus.Trial ||
                    subscriptionInfo?.billingStatus ===
                      BillingStatus.TrialEnding
                      ? "Trial End Date"
                      : "Next Billing Date"}
                  </h5>
                  <div className="mb-2">
                    {subscriptionInfo?.billingStatus === BillingStatus.Trial ||
                    subscriptionInfo?.billingStatus ===
                      BillingStatus.TrialEnding
                      ? "Your plan is scheduled to be canceled on "
                      : ""}
                    {BillingStatus.Unpaid === 4 &&
                    subscriptionInfo?.nextBillTime
                      ? formatUTCDateTime(
                          subscriptionInfo.nextBillTime,
                          "MMMM do, yyyy",
                        )
                      : "Your payment is currently past due. Please click on Manage Billing and pay your outstanding balance to not risk losing access to CData Connect Cloud."}
                  </div>
                  <div
                    className={
                      subscriptionInfo?.billingStatus === BillingStatus.Trial
                        ? "subtitle d-block"
                        : "d-none"
                    }
                  >
                    Enter your billing information before the trial ends to
                    continue using all features of CData Connect Cloud.
                  </div>
                </FormGroup>
                <FormGroup
                  hidden={
                    subscriptionInfo?.billingStatus ===
                      BillingStatus.Canceled || isOem
                  }
                  className="mb-0 row plan-contents"
                >
                  <div className="col-sm-2">
                    <h5 className="mb-0">Your Plan Includes</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="billing-check align-middle me-2 no-pointer-event mb-1"
                      />
                      {(subscriptionInfo?.limits?.userLimit < 0
                        ? "Unlimited"
                        : subscriptionInfo?.limits?.userLimit) +
                        (billingUsageInfo?.usersCount !== 1
                          ? " User Seats"
                          : " User Seat")}
                    </div>
                    {subscriptionInfo?.limits?.dataSourceLimit !== -1 ? (
                      <div>
                        <FontAwesomeIcon
                          icon={faCheck}
                          size="sm"
                          className="billing-check align-middle me-2 no-pointer-event mb-1"
                        />
                        {subscriptionInfo?.limits?.dataSourceLimit +
                          (subscriptionInfo?.limits?.dataSourceLimit !== 1
                            ? " Data Sources"
                            : " Data Source")}
                      </div>
                    ) : null}
                    <div>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="billing-check align-middle me-2 no-pointer-event mb-1"
                      />
                      {(subscriptionInfo?.limits?.connectionLimit < 0
                        ? "Unlimited"
                        : subscriptionInfo?.limits?.connectionLimit) +
                        (subscriptionInfo?.limits?.connectionLimit !== 1
                          ? " Connections"
                          : "Connection")}
                    </div>
                    {subscriptionInfo?.plan?.description !== null
                      ? subscriptionInfo?.plan?.description
                          .split("|")
                          .map((descriptionItem) => {
                            return (
                              <div key={descriptionItem}>
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="sm"
                                  className="billing-check align-middle me-2 no-pointer-event mb-1"
                                />
                                {descriptionItem}
                              </div>
                            );
                          })
                      : null}
                  </div>
                  {(subscriptionInfo?.purchasedAddons?.length > 0 ||
                    subscriptionInfo?.includedAddons?.length > 0) && (
                    <div className="col-sm-2">
                      <h5 className="mb-0">Add-Ons:</h5>
                      {subscriptionInfo?.includedAddons.map((addOn) => {
                        return (
                          <div key={addOn.name}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              className="billing-check align-middle me-2 no-pointer-event mb-1"
                            />
                            {addOn.name} (Included)
                          </div>
                        );
                      })}
                      {subscriptionInfo?.purchasedAddons.map((addOn) => {
                        return (
                          <div key={addOn.name}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              className="billing-check align-middle me-2 no-pointer-event mb-1"
                            />
                            {addOn.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </FormGroup>
              </CardBody>
            </Card>
          </div>
          <div className="col-auto d-flex">
            <Card>
              <CardBody className="sales-card-body">
                <Row>
                  <h2 className="mb-0">Update Plan</h2>
                </Row>
                <Row className="sales-prompt">
                  Looking to update your Connect Cloud subscription plan?
                  Contact our Sales team today to assist you in your request!
                </Row>
                <span className="phone-icon-bg">
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    size="lg"
                    className="phone-icon"
                  />
                </span>
                <p className="sales-contact-info mb-0">
                  <b>Mon-Fri, 9am-6pm EST</b>
                  <br />
                  (800) 235-7250 (within US)
                  <br />
                  (919) 928-5214 (outside US)
                </p>
                <a
                  href={`mailto:${getSalesEmailAddress()}`}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="sm"
                    className="align-middle me-2 no-pointer-event"
                  />
                  Email Sales
                </a>
              </CardBody>
            </Card>
          </div>
        </Row>
        {billingUsageInfo &&
        subscriptionInfo &&
        subscriptionInfo?.billingStatus !== BillingStatus.Canceled ? (
          <BillingUsageBars
            subscriptionInfo={subscriptionInfo}
            aggregateInfo={aggregateInfo}
            billingUsageInfo={billingUsageInfo}
          />
        ) : null}
      </div>
      {CancelBillingModal()}
    </Container>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addSubscription: (subscription: ISubscriptionInfo) =>
      dispatch(addSubscription(subscription)),
    addUsage: (billingUsageInfo: IBillingUsage) =>
      dispatch(addUsage(billingUsageInfo)),
  };
};

export default connect(
  (store: any) => ({
    subscription: store.subscription,
    catalog: store.catalog,
    billingUsageInfo: store.billingUsageInfo,
  }),
  mapDispatchToProps,
)(StripeBillingInfoTab);
