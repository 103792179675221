// @ts-strict-ignore
"use client";
/// <reference types="vite/client" />
/// <reference types="vite-plugin-svgr/client" />

import { useRoutes } from "react-router-dom";
import React, { Suspense, useRef } from "react";
import { Provider } from "react-redux";
import flagsmith from "flagsmith";
import { IDatadogRum } from "flagsmith/types";
import { FlagsmithProvider } from "flagsmith/react";

import { getRouterList } from "./routes/RoutesList";
import store from "./redux/store/index";
import { ToastrError } from "./services/toastrService";
import {
  IModalProps,
  CDataModal,
  IModalFunctionFromModal,
} from "./components/CDataModal";
import { DataDogUserLogger } from "./DataDogUserGetter";
import { ModalContext } from "./routes/ModalContext";
import { ToastContainer } from "react-toastify";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import Loader from "./components/Loader";

import "../src/assets/font-awesome-6-pro/css/all.css";
import "../src/assets/font-awesome-6-pro/css/fontawesome.css";
import { CDataAuth0Provider } from "./api/CDataAuth0Provider";
import { CDataQueryClientProvider } from "./api/CDataQueryClientProvider";
import { theme } from "./theme/muiTheme";
import state from "./flagsmith-frontend.json";
import { ErrorBoundary } from "react-error-boundary";
import {
  GenericErrorPage,
  logBoundaryErrorToDatadog,
} from "./pages/auth/GenericErrorPage";

const App = () => {
  const modalRef = useRef<IModalFunctionFromModal>(null);

  const modalFunctions = {
    showError: (text: string, error: any) => {
      ToastrError(text, error.message);
    },
    setModal: (modal: IModalProps) => {
      modalRef.current!.setModal(modal);
    },
    showTimeout: () => {
      modalRef.current!.showTimeout();
    },
    toggleModal: () => {
      modalRef.current!.toggleModal();
    },
    showUnsupportedBrowser: () => {
      modalRef.current!.showUnsupportedBrowser();
    },
  } as IModalFunctionFromModal;

  const content = useRoutes(
    getRouterList(
      modalFunctions.showError,
      modalFunctions.showTimeout,
      modalFunctions.showUnsupportedBrowser,
      modalFunctions.setModal,
      modalFunctions.toggleModal,
    ),
  );

  // Send the flagsmith flags to DataDog if DataDog is loaded (in BSEs and prod)
  const flagsmithDataDogOptions: IDatadogRum | undefined =
    window.DD_RUM != null
      ? {
          client: window.DD_RUM,
          trackTraits: true,
        }
      : undefined;

  const flagsmithState = {
    ...state,
    environmentID: window.VITE_CONFIG.VITE_FLAGSMITH_ENVIRONMENT_ID,
  };

  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <GenericErrorPage
          error={props.error}
          isOutsideDashboard={true}
          resetErrorBoundary={props.resetErrorBoundary}
        />
      )}
      onError={logBoundaryErrorToDatadog}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CDataAuth0Provider>
            <CDataQueryClientProvider>
              <StyledEngineProvider injectFirst>
                <FlagsmithProvider
                  options={{
                    cacheFlags: true,
                    datadogRum: flagsmithDataDogOptions,
                    environmentID:
                      window.VITE_CONFIG.VITE_FLAGSMITH_ENVIRONMENT_ID,
                    state: flagsmithState,
                  }}
                  flagsmith={flagsmith}
                >
                  <ModalContext.Provider value={modalFunctions}>
                    {/*
                    This Suspense is only here as a fallback if a route doesn't properly have a Suspense
                    of its own and uses lazy import.
                    Any route using the <Dashboard /> component will show the nav bars and a loading spinner
                    in the body of the page while the javascript bundle loads.
                    */}
                    <Suspense fallback={<Loader />}>{content}</Suspense>
                  </ModalContext.Provider>
                </FlagsmithProvider>
                {window.VITE_CONFIG.VITE_MONITORING_ENABLED ? (
                  <DataDogUserLogger />
                ) : null}
                <CDataModal
                  ref={modalRef}
                  displayed={false}
                  title=""
                  body={<React.Fragment />}
                  primaryButton={<React.Fragment />}
                  secondaryButton={<React.Fragment />}
                  displayToggleCloseButton={true}
                  close={() => {
                    return;
                  }}
                  modalSize="lg"
                  removeBorders={false}
                  className=""
                />
              </StyledEngineProvider>
            </CDataQueryClientProvider>
          </CDataAuth0Provider>
          <ToastContainer position="top-center" />
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
