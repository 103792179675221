// @ts-strict-ignore
import {
  BillingStatus,
  IBillingUsage,
  ISubscriptionInfo,
  IUser,
  UserRole,
} from "../../models/";
import React, { ReactElement } from "react";
import { browserName } from "react-device-detect";

import { NotificationBar } from "./NotificationBar";
import {
  INotificationBarProps,
  NotificationTopic,
  NotificationType,
} from "./INotificationBar";

import NotificationBarConstants, {
  ACTIVATE_PLAN,
  BILLING_TAB_DESTINATION,
  LEARN_MORE,
  MANAGE_BILLING,
  SUPPORTED_BROWSERS,
  UPGRADE_PLAN,
  VIEW_WORKSPACES,
  WORKSPACE_TAB_DESTINATION,
} from "./NotificationBarConstants";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { isMacSafariWebView } from "./NotificationBarUtil";
import { differenceInCalendarDays, parseISO } from "date-fns";

interface INotificationBarManagerProps {
  notification: INotificationBarProps;
}

export const NotificationBarManager = (
  props: INotificationBarManagerProps,
): ReactElement => {
  function getComponentName() {
    return "components-NotificationBarManager";
  }
  const notificationBarManager: ReactElement | null = props.notification ? (
    <NotificationBar {...props.notification} />
  ) : null;

  return (
    <div className={`${getComponentName()}`}>{notificationBarManager}</div>
  );
};

// Alerts users about overages for user seats, connections, and both
export function addOverageNotification(
  user: Partial<IUser>,
  accountUsage: IBillingUsage,
): INotificationBarProps | null {
  const isUserSeatOverLimit = accountUsage?.usersOverLimit;
  const isConnectionOverLimit =
    accountUsage?.connectionsOverLimit || accountUsage?.dataSourcesOverLimit;

  if (isUserSeatOverLimit || isConnectionOverLimit) {
    const isUserRoleAdmin = user.role === UserRole.Admin;
    const overageMessage =
      isUserSeatOverLimit && isConnectionOverLimit
        ? isUserRoleAdmin
          ? NotificationBarConstants.overageMessagesAdmin
              .userSeatsAndConnections
          : NotificationBarConstants.overageMessagesQuery
              .userSeatsAndConnections
        : isUserSeatOverLimit
          ? isUserRoleAdmin
            ? NotificationBarConstants.overageMessagesAdmin.userSeats
            : NotificationBarConstants.overageMessagesQuery.userSeats
          : isUserRoleAdmin
            ? NotificationBarConstants.overageMessagesAdmin.connections
            : NotificationBarConstants.overageMessagesQuery.connections;

    return {
      message: overageMessage,
      type: NotificationType.Alert,
      topic: NotificationTopic.Billing,
      buttonText: isUserRoleAdmin ? UPGRADE_PLAN : null,
      to: isUserRoleAdmin ? BILLING_TAB_DESTINATION : null,
    } as INotificationBarProps;
  }
  return null;
}

// Informs trial user who has exceeded their row limit that they need to upgrade
export function addTrialRowNotification(
  user: Partial<IUser>,
  accountUsage: IBillingUsage,
  currentSubscription: Partial<ISubscriptionInfo>,
): INotificationBarProps | null {
  const role: number = user.role!;

  if (
    (currentSubscription.billingStatus === BillingStatus.NewAccount ||
      currentSubscription.billingStatus === BillingStatus.Trial ||
      currentSubscription.billingStatus === BillingStatus.TrialEnding) &&
    accountUsage?.rowsOverLimit
  ) {
    return {
      message:
        role === UserRole.Admin
          ? NotificationBarConstants.trialRowOverageMessages.admin
          : NotificationBarConstants.trialRowOverageMessages.query,
      type: NotificationType.Alert,
      topic: NotificationTopic.Other,
      buttonText: role === UserRole.Admin ? UPGRADE_PLAN : null,
      to: role === UserRole.Admin ? BILLING_TAB_DESTINATION : null,
    } as INotificationBarProps;
  }
  return null;
}

// Alerts user whose account is unpaid, canceled, or paused that they need to fix things
export function addBillingIssueNotification(
  user: Partial<IUser>,
  currentSubscription: Partial<ISubscriptionInfo>,
): INotificationBarProps | null {
  const role: number = user.role!;
  const notificationData: INotificationBarProps = {
    message: <></>,
    type: NotificationType.Alert,
    topic: NotificationTopic.Billing,
    buttonText: null,
    to: undefined,
  };

  switch (currentSubscription.billingStatus) {
    case BillingStatus.Unpaid:
      notificationData.message =
        role === UserRole.Admin
          ? NotificationBarConstants.billingIssueMessages.unpaidAdmin
          : NotificationBarConstants.billingIssueMessages.unpaidQuery;
      notificationData.buttonText =
        role === UserRole.Admin ? MANAGE_BILLING : null;
      notificationData.to =
        role === UserRole.Admin ? BILLING_TAB_DESTINATION : undefined;
      break;
    case BillingStatus.Canceled:
      notificationData.message =
        role === UserRole.Admin
          ? NotificationBarConstants.billingIssueMessages.canceledAdmin
          : NotificationBarConstants.billingIssueMessages.canceledQuery;
      notificationData.buttonText =
        role === UserRole.Admin ? MANAGE_BILLING : null;
      notificationData.to =
        role === UserRole.Admin ? BILLING_TAB_DESTINATION : undefined;
      break;
    case BillingStatus.Paused:
      notificationData.message =
        role === UserRole.Admin
          ? NotificationBarConstants.billingIssueMessages.pausedAdmin
          : NotificationBarConstants.billingIssueMessages.pausedQuery;
      notificationData.buttonText =
        role === UserRole.Admin ? ACTIVATE_PLAN : null;
      notificationData.to =
        role === UserRole.Admin ? BILLING_TAB_DESTINATION : undefined;
      break;
    default:
      return null;
  }
  return notificationData;
}

// Informs trial user how many days they have left in their trial or, alternatively, that their trial has expired
export function addTrialNotification(
  user: Partial<IUser>,
  currentSubscription: Partial<ISubscriptionInfo>,
): INotificationBarProps | null {
  if (
    ![
      BillingStatus.NewAccount,
      BillingStatus.Trial,
      BillingStatus.TrialEnding,
    ].includes(currentSubscription.billingStatus!) ||
    window.location.pathname === "/odata" ||
    window.location.pathname === "/odata/editTable"
  ) {
    return null;
  }

  const nextBillTime: string = currentSubscription.nextBillTime!;
  const days: number | null = nextBillTime
    ? differenceInCalendarDays(parseISO(nextBillTime), new Date())
    : null;
  const expiry: string =
    days && days >= 0 ? `is expiring in ${days} days!` : "has expired.";
  const role: number = user.role!;

  const message: ReactElement =
    role === UserRole.Admin ? (
      <>
        Your CData Connect Cloud trial {expiry} To avoid disruptions in service,
        please contact&nbsp;
        <a href={`mailto:${getSalesEmailAddress()}`}>
          {getSalesEmailAddress()}
        </a>
        &nbsp;or click Upgrade Plan.
      </>
    ) : (
      <>
        Your CData Connect Cloud trial {expiry} To avoid disruptions in service,
        please contact your system administrator to upgrade your plan today.
      </>
    );

  return {
    message,
    type: NotificationType.Info,
    topic: NotificationTopic.Other,
    buttonText: role === UserRole.Admin ? UPGRADE_PLAN : null,
    to: role === UserRole.Admin ? BILLING_TAB_DESTINATION : null,
  } as INotificationBarProps;
}

// Informs user with an unsupported browser that they should switch to a supported one
export function addBrowserNotification(
  showBrowserModal: Function,
): INotificationBarProps | null {
  if (
    !SUPPORTED_BROWSERS.includes(browserName) &&
    !isMacSafariWebView(navigator.userAgent)
  ) {
    return {
      message: NotificationBarConstants.unsupportedBrowser.message,
      type: NotificationType.Info,
      topic: NotificationTopic.Other,
      buttonText: LEARN_MORE,
      handleClick: showBrowserModal,
    } as INotificationBarProps;
  }
  return null;
}

// On the OData page, informs user about the deprecated OData and advises them to make a transition to Virtual Datasets
export function addODataNotification(
  datasetsFlagEnabled: boolean,
): INotificationBarProps | null {
  if (
    datasetsFlagEnabled &&
    (window.location.pathname === "/odata" ||
      window.location.pathname === "/odata/editTable")
  ) {
    return {
      message: NotificationBarConstants.deprecatedOData.message,
      type: NotificationType.Warning,
      topic: NotificationTopic.Other,
      buttonText: VIEW_WORKSPACES,
      to: WORKSPACE_TAB_DESTINATION,
    } as INotificationBarProps;
  }
  return null;
}

// Alerts users on the Cache Connection page that updating their cache connection will result in all jobs re-running
export function addCacheConnectionNotification(): INotificationBarProps | null {
  if (window.location.pathname === "/caching/cacheConnection") {
    return {
      message: NotificationBarConstants.modifyingCacheConnection.message,
      type: NotificationType.Warning,
      topic: NotificationTopic.Other,
      overrideIcon: faExclamationTriangle,
    };
  }

  return null;
}
