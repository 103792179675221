import React from "react";
import { FeatureId, UserRole } from "../../models";
import ODataIcon from "../../assets/img/icons/ODataRoute.svg?react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";

enum SidebarOptions {
  Overview = "Overview",
  Connections = "Connections",
  ClientTools = "Client Tools",
  DataExplorer = "Data Explorer",
  Datasets = "Virtual Datasets",
  Caching = "Jobs",
  Users = "Users",
  OData = "OData",
  Logs = "Logs",
  Api = "API",
  SupportImpersonation = "Support Impersonation",
}

export interface IRoutePath {
  name: SidebarOptions;
  icon: JSX.Element;
  path: string;
  showSidebarItem: boolean;
  isNew: boolean;
  testId?: string;
}

const getSidebarOptionsByRole = (role: UserRole): SidebarOptions[] => {
  const {
    Overview,
    Connections,
    ClientTools,
    DataExplorer,
    Datasets,
    Caching,
    Users,
    OData,
    Logs,
    Api,
    SupportImpersonation,
  } = SidebarOptions;

  switch (role) {
    case UserRole.Admin:
      return [
        Overview,
        Connections,
        ClientTools,
        DataExplorer,
        Datasets,
        Caching,
        Users,
        OData,
        Logs,
        Api,
        SupportImpersonation,
      ];
    case UserRole.Query:
      return [Connections, ClientTools, DataExplorer, Datasets, Logs, Api];
    case UserRole.OEMAdmin:
      return [Overview, Connections, Logs];
    case UserRole.ServiceUser:
      return [Overview, Connections, Logs];
    default:
      return [];
  }
};

const getMasterRoutePathList = (role: UserRole): IRoutePath[] => {
  const {
    Overview,
    Connections,
    ClientTools,
    DataExplorer,
    Datasets,
    Caching,
    Users,
    OData,
    Logs,
    Api,
    SupportImpersonation,
  } = SidebarOptions;
  const flags = useFeatureFlags().getFlags([
    "caching",
    "datasets_client",
    "odata_client",
    "impersonation",
  ]);
  const subscription = useSelector((state: RootState) => state.subscription);

  const isCachingEnabled =
    isFeatureAllowed(
      subscription?.limits?.availableFeatureIds ?? [],
      FeatureId.Caching,
    ) && flags.caching.enabled;

  const isODataVisible =
    window.sessionStorage.getItem("isODataVisible") === "true";

  const canImpersonateAsSupport =
    flags.impersonation.enabled &&
    window.sessionStorage.getItem("userInfo") &&
    JSON.parse(window.sessionStorage.getItem("userInfo") || "{}")
      .canImpersonateAsSupport === true;

  return [
    {
      name: Overview,
      icon: <i className="fa-regular fa-wave-pulse fa-lg" />,
      path: role === UserRole.ServiceUser ? "/oem/child/overview" : "/",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: Connections,
      icon: <i className="fa-regular fa-database fa-lg" />,
      path:
        role === UserRole.ServiceUser
          ? "/oem/child/connections"
          : "/connections",
      showSidebarItem: true,
      isNew: false,
      testId: "connections-sidebar-link",
    },
    {
      name: ClientTools,
      icon: <i className="fa-regular fa-link fa-lg" />,
      path: role === UserRole.Admin ? "/clients" : "/",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: DataExplorer,
      icon: <i className="fa-regular fa-magnifying-glass fa-lg" />,
      path: "/data-explorer",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: Datasets,
      icon: <i className="fa-regular fa-folder fa-lg" />,
      path: "/datasets",
      showSidebarItem: flags.datasets_client.enabled,
      isNew: false,
    },
    {
      name: Caching,
      icon: <i className="fa-regular fa-clone fa-lg" />,
      path: "/caching",
      showSidebarItem: isCachingEnabled,
      isNew: true,
    },
    {
      name: Users,
      icon: <i className="fa-regular fa-user fa-lg" />,
      path: "/user",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: OData,
      icon: (
        <ODataIcon className="fa-regular fa-lg" aria-description="OData logo" />
      ),
      path: "/odata",
      showSidebarItem: flags.odata_client.enabled && isODataVisible,
      isNew: false,
    },
    {
      name: Logs,
      icon: <i className="fa-regular fa-file-lines fa-lg" />,
      path: role === UserRole.ServiceUser ? "/oem/child/logs" : "/logs",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: Api,
      icon: <i className="fa-regular fa-code fa-lg" />,
      path: "https://cloud.cdata.com/docs/API.html",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: SupportImpersonation,
      icon: <i className="fa-regular fa-file-lines fa-user-headset" />,
      path: "/impersonation",
      showSidebarItem: canImpersonateAsSupport,
      isNew: false,
    },
  ];
};

const getRoutePaths = (role: UserRole): IRoutePath[] => {
  const masterRoutePaths: IRoutePath[] = getMasterRoutePathList(role);
  const sidebarOptions: SidebarOptions[] = getSidebarOptionsByRole(role);

  return masterRoutePaths.filter((routePath) =>
    sidebarOptions.includes(routePath.name),
  );
};

export default getRoutePaths;
